import React from 'react';
import LoginPage from "./pages/LoginPage";
import {Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import SignupPage from "./pages/SignupPage";
import SubtitleGenerationPage from "./pages/SubtitleGenerationPage";
import QurationPage from "./pages/QurationPage";
import FileUploadPage from "./pages/FileUploadPage";
import MyPage from "./pages/MyPage";
import ProjectPage from "./pages/ProjectPage";
import SourceSeparationPage from "./pages/SourceSeparationPage";


const App = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/mypage" element={<MyPage/>}/>
            <Route path="/project" element={<ProjectPage/>}/>
            <Route path="/upload" element={<FileUploadPage/>}/>
            <Route path="/subtitle-generation" element={<SubtitleGenerationPage/>}/>
            <Route path="/source-separation" element={<SourceSeparationPage/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/signup" element={<SignupPage/>}/>
            <Route path="/quration" element={<QurationPage/>}/>
            {/*<Route path="/dashboard" element={<DashboardPage/>}/>*/}
            {/*<Route path="/result" element={<ResultPage/>}/>*/}
        </Routes>
    );
};

export default App;

