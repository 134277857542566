import React, {useEffect, useState} from 'react';
import {Form, Spinner} from 'reactstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import {handleSubmit} from "../apis/subtitle";
import {
    Content,
    MainContainer,
    MainContent,
} from "../styles/styles";
import Menubar from "../components/MenuBar";
import Nav from "../components/Nav";
import {
    FileButtonStyle,
    FileUploadContainer,
    FileUploadContent,
    FileUploadSubTitle,
    FileUploadTitle,
    FileUploadTitleContainer,
    SpeakerOption,
    InstrumentOption
} from "../styles/FileUploadStyles";
import ProgressBar from "../components/ProgressBar";
import {fetchVideoInfo} from "../apis/youtubeData";
import {handleSeparation} from "../apis/source_separation";  // Import the fetchVideoInfo function

const INSTRUMENTS = {
    '보컬': 'vocals',
    '드럼': 'drums',
    '베이스': 'bass',
    '피아노': 'piano',
    '그외': 'other',
};

const FileUploadPage = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    // useLocation을 사용하여 state 값을 가져옴
    const location = useLocation();
    const { isSourceSeparation, isSubtitleGeneration } = location.state || {};

    const [file, setFile] = useState(null);
    const [youtubeUrl, setYoutubeUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [subtitle, setSubtitle] = useState(null);
    const [summary, setSummary] = useState(null);
    const [result, setResult] = useState([]);
    const [text, setText] = useState("");
    const [progress, setProgress] = useState(0);
    const [videoTitle, setVideoTitle] = useState('');
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [num_speakers, setNumSpeakers] = useState(0);  // Default to 0 (auto)
    const [selectedInstruments, setSelectedInstruments] = useState([]);

    const {step, percent} = progress;
    const navigate = useNavigate();

    useEffect(() => {
        console.log('step:', step, 'percent:', percent);
        if (percent === 100) {
            console.log('progress:', progress);
            // 먼저 Source Separation을 확인
            if (isSourceSeparation) {
                const timer = setTimeout(() => {
                    alert('음원 분리가 완료되었습니다.');
                    navigate('/source-separation', {
                        state: {
                            result,
                            selectedInstruments
                        }
                    });
                }, 1000);
                return () => clearTimeout(timer);
            } else if (subtitle !== null && summary !== null && isSubtitleGeneration) {
                const timer = setTimeout(() => {
                    alert('자막 생성이 완료되었습니다.');
                    navigate('/subtitle-generation', {
                        state: {
                            subtitle,
                            result,
                            text,
                            summary
                        }
                    });
                }, 1000);
                return () => clearTimeout(timer);
            }
        }
    }, [step, subtitle, navigate, result, text, summary]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setYoutubeUrl(selectedFile ? selectedFile.name : "");
    };

    const handleUrlChange = (e) => {
        setYoutubeUrl(e.target.value);
    };

    const parseText = (text) => {
        const result = [];
        const score = text.result.s2t
        const segments = text.result.subtitle.segments;

        for (let i = 0; i < segments.length; i++) {
            const speakerNumber = segments[i].speaker.match(/SPEAKER-(\d+)/)[1];
            // 숫자를 알파벳으로 변환하는 로직 ('00' -> 'A', '01' -> 'B' ...)
            const speakerLetter = String.fromCharCode(65 + parseInt(speakerNumber, 10));

            result.push({
                start: segments[i].start,
                end: segments[i].end,
                text: segments[i].text,
                speaker: speakerLetter,
                path: segments[i].filename,
                segment: i,
                score: score.utterances[i].score
            });
        }

        let textStr = '';
        for (let i = 0; i < result.length; i++) {
            textStr += result[i].text + ' ';
        }

        setText(textStr);
        setResult(result);

        return textStr;
    };

    // Handle num_speakers increase
    const handleSpeakerIncrease = () => {
        setNumSpeakers((prevSpeakers) => prevSpeakers + 1);
    };

    // Handle num_speakers decrease
    const handleSpeakerDecrease = () => {
        setNumSpeakers((prevSpeakers) => (prevSpeakers > 0 ? prevSpeakers - 1 : 0));
    };

    // Handle instrument selection
    const handleInstrumentChange = (instrument) => {
        setSelectedInstruments(prevInstruments =>
            prevInstruments.includes(instrument)
                ? prevInstruments.filter(i => i !== instrument)
                : [...prevInstruments, instrument]
        );
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            if (file === null && youtubeUrl === "") {
                alert("동영상 URL을 입력하거나 파일을 업로드해주세요.");
                setIsLoading(false);
                return;
            }

            if (file === null && youtubeUrl !== "") {
                await fetchVideoInfo(youtubeUrl, setVideoTitle, setThumbnailUrl);  // Fetch video info using the separate function
            } else {
                setVideoTitle(file.name);
                setThumbnailUrl(null);
            }

            console.log("file:", file, "youtubeUrl:", youtubeUrl, "num_speakers:", num_speakers);
            await handleSubmit(e, {
                file, youtubeUrl, setIsLoading, BACKEND_URL,
                setSubtitle, parseText, setProgress, setSummary, num_speakers
            });

        } catch (error) {
            console.error("Error during submission:", error);
        }
    };

    const handleSourceSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            if (file === null && youtubeUrl === "") {
                alert("동영상 URL을 입력하거나 파일을 업로드해주세요.");
                setIsLoading(false);
                return;
            }

            if (selectedInstruments.length === 0) {
                alert("악기를 선택해주세요.");
                setIsLoading(false);
                return;
            }

            if (file === null && youtubeUrl !== "") {
                await fetchVideoInfo(youtubeUrl, setVideoTitle, setThumbnailUrl);  // Fetch video info using the separate function
            }

            const instrumentsString = selectedInstruments.join(',');  // Convert selected instruments to a comma-separated string

            await handleSeparation(e, {
                file, youtubeUrl, setIsLoading, BACKEND_URL, setProgress, instrumentsString, setResult
            });

        } catch (error) {
            console.error("Error during submission:", error);
        }
    };

    return (
        <MainContainer>
            <Menubar/>
            <Content>
                <Nav/>
                <MainContent>
                    <FileUploadContainer>
                        <FileUploadTitleContainer>
                            {isSubtitleGeneration && (<FileUploadTitle>자막 생성기</FileUploadTitle>)}
                            {!isSubtitleGeneration && (<FileUploadTitle>음원 분리기</FileUploadTitle>)}
                            {isSubtitleGeneration && (<FileUploadSubTitle>자막을 생성하고 싶은 동영상 URL을 입력하거나 동영상 파일을 업로드해주세요. 파일 형식은 mp4,....최대 2GB까지
                                지원합니다.</FileUploadSubTitle>)}
                            {!isSubtitleGeneration && (<FileUploadSubTitle>음원을 분리하고 싶은 동영상 URL을 입력하거나 동영상 파일을 업로드해주세요. 파일 형식은 mp4,....최대 2GB까지
                                지원합니다.</FileUploadSubTitle>)}
                        </FileUploadTitleContainer>
                        <FileUploadContent>
                            <div className="upload-title">동영상 업로드</div>
                            {isSubtitleGeneration && (<SpeakerOption>
                                <div className='label'>화자 수</div>
                                <div>
                                    <button className='btn' type="button" onClick={handleSpeakerDecrease}>-
                                    </button>
                                    <span>{num_speakers === 0 ? '자동' : num_speakers}</span>
                                    <button className='btn' type="button" onClick={handleSpeakerIncrease}
                                            style={{marginLeft: '10px'}}>+
                                    </button>
                                </div>
                            </SpeakerOption>)}
                            {isSourceSeparation && (<InstrumentOption>
                                    <div className='label'>악기 선택</div>
                                    {Object.keys(INSTRUMENTS).map(instrument => (
                                        <div key={instrument}>
                                            <input
                                                type="checkbox"
                                                id={INSTRUMENTS[instrument]}
                                                name={INSTRUMENTS[instrument]}
                                                value={INSTRUMENTS[instrument]}
                                                onChange={() => handleInstrumentChange(INSTRUMENTS[instrument])}
                                            />
                                            <label htmlFor={INSTRUMENTS[instrument]}>{instrument}</label>
                                        </div>
                                    ))}
                            </InstrumentOption>)}
                            <Form className='form' onSubmit={isSourceSeparation ? handleSourceSubmit : handleFormSubmit}>
                                <div className='url'>
                                    <input
                                        type="text"
                                        placeholder="동영상 URL을 입력하세요"
                                        value={youtubeUrl}
                                        onChange={handleUrlChange}
                                    />
                                    <FileButtonStyle type="submit" disabled={isLoading}>
                                        생성하기
                                    </FileButtonStyle>
                                </div>
                                <div className="finder">
                                    <div>동영상 URL이 없다면?</div>
                                    <input
                                        id="file-input"
                                        type="file"
                                        accept=".mp4, .avi, .mov, mp3, .wav, .flac, .aac"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="file-input">파일 업로드 하기</label>
                                </div>
                                {/* Speaker Count */}
                                {isLoading && (
                                    <ProgressBar progress={progress} thumbnailUrl={thumbnailUrl}
                                                 videoTitle={videoTitle}/>
                                )}
                            </Form>
                        </FileUploadContent>
                        {/*<button className='source-btn' onClick={handleSourceSubmit}>음원 분리</button>*/}
                    </FileUploadContainer>
                </MainContent>
            </Content>
        </MainContainer>
    );
};

export default FileUploadPage;
