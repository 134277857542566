import React, {useState, useRef} from 'react';
import {Form, Input, Spinner, Col, Row} from 'reactstrap';
import MenuBar from '../components/MenuBar';
import {useNavigate} from "react-router-dom";
import {MainContainer} from "../styles/styles";

const HomePage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    // 제품 큐레이션으로 이동
    const onClickQuration = () => {
        navigate('/mypage');
    }

    return (
        <MainContainer>
            <MenuBar />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                width: '100%',
                height: '100vh',
                padding: '100px',
            }}>
                <div style={{
                    fontSize: '54px',
                    fontWeight: '400',
                    marginBottom: '40px',
                }}>
                    Unlock the Power of Voice with{' '}
                    <span style={{
                        fontWeight: '800',
                    }}>audion
                    </span>
                </div>
                <div style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    marginBottom: '80px',
                    color: '#737373',
                }}>Your AI audio platform for tailored voice experiences.<br/>Choose only the features you need and
                    create a fully customized audio
                    experience.<br/>Transform the way you interact—seamlessly integrate voice into your daily life with
                    the precision of AI.<br/>
                    <br/>
                    Your voice, your way.
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    height: '100px',
                }}>
                    <input
                        style={{
                            width: '600px',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            outline: 'none',
                            fontSize: '16px',
                            marginBottom: '50px',
                            marginRight: '10px',
                        }}
                        type="text"
                        placeholder="제품 키워드, 기술 등을 입력해주세요."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button
                        style={{
                            width: '100px',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            outline: 'none',
                            fontSize: '16px',
                            marginBottom: '50px',
                            marginLeft: '10px',
                        }}>제품 검색
                    </button>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    height: '100px',
                }}>
                    <button
                        style={{
                            width: '200px',
                            height: '50px',
                            padding: '10px',
                            borderRadius: '12px',
                            outline: 'none',
                            fontSize: '16px',
                            backgroundColor: 'black',
                            color: 'white',
                            fontWeight: '700',
                            marginRight: '10px',
                        }} onClick={onClickQuration}>프로젝트 생성하기
                    </button>
                    <div style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        letterSpacing: '0.025em',
                    }}>어떤 기술이 필요한지 잘 모르시겠다면,<br/>
                        Audion의 맞춤형 추천 큐레이션을 받아보세요.</div>
                </div>
                    {/*<button onClick={onClickSubtitleGeneration}>Subtitle Generation</button>*/}
                </div>
        </MainContainer>
);
}

export default HomePage;
