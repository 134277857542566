import React, { useState } from 'react';
import logo from "../assets/signup_logo.svg";
import axios from 'axios';
import {
    Form, FormContainer,
    FormSection,
    ImageSection,
    Input,
    Label,
    SignupContainer,
    SubmitButton
} from "../styles/signup/SignupStyles";
import {useNavigate} from "react-router-dom";

const Signup = () => {
    const [nickname, setNickname] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [affiliation, setAffiliation] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


    // 유효성 검사 함수들
    const checkNickname = (nickname) => {
        const valid = /^[a-zA-Z0-9_-]+$/.test(nickname);
        if (!valid) {
            setError("이름은 알파벳과 숫자만 포함해야 합니다. 특수문자는 '_'와 '-'만 허용됩니다.");
        }
        return valid;
    };

    const checkEmail = (email) => {
        const valid = email.includes("@");
        if (!valid) {
            setError("올바른 이메일 주소를 입력해주세요.");
        }
        return valid;
    };

    const checkPassword = (password) => {
        const valid = password.length >= 8 &&
            /\d/.test(password) &&
            /[a-zA-Z]/.test(password) &&
            /[^a-zA-Z0-9]/.test(password);

        if (!valid) {
            setError("비밀번호는 8자 이상이어야 하며, 숫자, 문자, 특수문자를 포함해야 합니다.");
        }
        return valid;
    };

    const checkRepeatPassword = (password, repeatPassword) => {
        const valid = password === repeatPassword;
        if (!valid) {
            setError("비밀번호가 일치하지 않습니다.");
        }
        return valid;
    };

    // 폼 제출 함수
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        // 모든 유효성 검사를 통과한 경우
        if (checkNickname(nickname) && checkEmail(email) && checkPassword(password) && checkRepeatPassword(password, repeatPassword)) {
            try {
                // 서버에 요청 보내기 (추후 서버 URL로 수정 필요)
                const response = await axios.post(`${BACKEND_URL}/signup`, {
                    nickname,
                    username,
                    email,
                    affiliation,
                    password,
                });

                console.log(response.data);

                if (response.data.status === 'success') {
                    setSuccess(true);
                    setError(null);
                    console.log('회원가입 성공');
                } else {
                    setSuccess(false);
                    setError("회원가입에 실패했습니다. 다시 시도해주세요.");
                    console.log('회원가입 실패');
                }
            } catch (error) {
                setSuccess(false);
                setError("서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
                console.error(error);
            }
        }
    };

    return (
        <SignupContainer>
            {/*<ImageSection/>*/}
            <FormSection>
                <FormContainer>
                    <img src={logo} alt="logo"/>
                    <div className='header'>오디온 회원가입</div>
                    {error && <div className="error-message">{error}</div>}
                    {success ? (
                        <div className="success-message">
                            <h2>회원가입에 성공했습니다!</h2>
                            <p>로그인해주세요.</p>
                        </div>
                    ) : (
                        <Form onSubmit={handleSubmit}>

                            <Label>이름 *</Label>
                            <Input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                            <Label>아이디 *</Label>
                            <Input
                                type="text"
                                value={nickname}
                                onChange={(e) => setNickname(e.target.value)}
                                required
                            />
                            <Label>Email *</Label>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <Label>소속 (선택 사항)</Label>
                            <Input
                                type="text"
                                value={affiliation}
                                onChange={(e) => setAffiliation(e.target.value)}
                            />
                            <Label>Password *</Label>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <Label>Repeat Password *</Label>
                            <Input
                                type="password"
                                value={repeatPassword}
                                onChange={(e) => setRepeatPassword(e.target.value)}
                                required
                            />
                            <SubmitButton type="submit">회원가입 완료</SubmitButton>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <div style={{color: '#605D64', fontSize: 14, fontWeight: 600, margin: '20px 0 20px 0'}}>
                                    이미 계정이 있으신가요?
                                </div>
                                <button onClick={() => navigate('/login')} style={{
                                    color: '#6D878C',
                                    fontSize: 16,
                                    fontFamily: 'Pretendard, sans-serif',
                                    fontWeight: 600,
                                    margin: '20px 0 20px 0',
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer'
                                }}>로그인
                                </button>
                            </div>
                        </Form>
                    )}
                </FormContainer>
            </FormSection>
        </SignupContainer>
    );
};

export default Signup;
